@import '~flatpickr/dist/themes/light.css';

.flatpickr-calendar {
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--color-neutral-800);
  background: var(--color-white);
  box-shadow: var(--shadow-large);
  border-radius: 8px;
}

.flatpickr-day {
  display: inline-flex;
  align-items: center;
  width: 32px;
  height: 32px;
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border-radius: var(--border-radius-default);
  border: 1px solid transparent;
}

.flatpickr-day:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay:hover {
  color: var(--color-neutral-800);
  background: var(--color-primary-100);
  border-color: var(--color-primary-100);
}

.flatpickr-day.today {
  border: 1px solid var(--color-neutral-400);
}

.flatpickr-day.today:hover {
  color: var(--color-neutral-800);
  background: var(--color-primary-100);
}

span.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  color: var(--color-primary-700);
  font-weight: 600;
  background: var(--color-primary-300);
  border-radius: var(--border-radius-default);
  border: 1pix solid var(--color-primary-300);
  box-shadow: none;
}

.flatpickr-day.selected:hover {
  color: var(--color-neutral-800);
  background: var(--color-primary-300);
  border-color: var(--color-primary-300);
}

.flatpickr-months .flatpickr-month {
  color: var(--color-neutral-800);
  background-color: var(--color-white);
}

.flatpickr-current-month {
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

span.flatpickr-weekday {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-family: var(--typography-body);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-neutral-800);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  top: 0.3em;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  top: 0.3em;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  direction: rtl;
  width: 50%;
  background: var(--color-white);
  font-weight: normal;
  appearance: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: none;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  box-shadow: 0 0 0 var(--color-primary-100), 0 0 0 var(--color-primary-100) !important;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  box-shadow: -5px 0 0 var(--color-primary-100), 1px 0 0 var(--color-primary-100) !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: var(--color-primary-100);
  border-color: var(--color-primary-100);
}

.flatpickr-day.today.inRange {
  border-radius: var(--border-radius-default);
  border-color: var(--color-neutral-400);
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: var(--border-radius-default);
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 var(--color-primary-100);
}

.flatpickr-current-month .numInputWrapper {
  width: 50%;
  background: var(--color-white);
}

.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
  background: var(--color-white);
}

.flatpickr-calendar.animate.open {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12), 0px 0px 50px rgba(0, 0, 0, 0.1);
}

.flatpickr-innerContainer {
  border-bottom: none;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  color: var(--color-neutral-800);
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--color-neutral-800);
}

.flatpickr-day.inRange {
  box-shadow: -5px 0 0 var(--color-primary-100), 5px 0 0 var(--color-primary-100);
}
